import debounce from "debounce";
import { Graphics } from "pixi.js";
import { RefObject } from "react";
import * as simplex from "simplex-noise";
import { random } from "./random";

interface Bound {
  min: number;
  max: number;
}

interface Bounds {
  x: Bound;
  y: Bound;
}

interface Args {
  fill: string;
  posX?: number;
  posY?: number;
  canvasRef?: RefObject<HTMLCanvasElement>;
}

export class Orb {
  bounds: Bounds;
  x: number;
  y: number;
  scale: number;
  fill: string;
  xOff: number;
  yOff: number;
  radius: number;
  inc: number;
  graphics: Graphics;
  noise2D: simplex.NoiseFunction2D;

  // Pixi takes hex colors as hexadecimal literals (0x rather than a string with '#')
  constructor({ fill = "0x000000", posX, posY, canvasRef }: Args) {
    // bounds = the area an orb is "allowed" to move within
    this.bounds = this.setBounds({
      canvasHeight: canvasRef?.current?.height,
      posX,
      posY,
    });
    // initialize the orb's { x, y } values to a random point within it's bounds
    this.x = random(this.bounds.x.min, this.bounds.x.max);
    this.y = random(this.bounds.y.min, this.bounds.y.max);

    // how large the orb is vs it's original radius (this will modulate over time)
    this.scale = 1;

    // what color is the orb?
    this.fill = fill;

    // the original radius of the orb, set relative to window height
    this.radius = random(window.innerHeight / 6, window.innerHeight / 3);

    // starting points in "time" for the noise/self similar random values
    this.xOff = random(0, 1000);
    this.yOff = random(0, 1000);
    // how quickly the noise/self similar random values step through time
    this.inc = 0.002;

    // PIXI.Graphics is used to draw 2d primitives (in this case a circle) to the canvas
    this.graphics = new Graphics();
    this.graphics.alpha = 0.825;

    // 250ms after the last window resize event, recalculate orb positions.
    window.addEventListener(
      "resize",
      debounce(() => {
        this.bounds = this.setBounds({
          canvasHeight: canvasRef?.current?.height,
          posX,
          posY,
        });
      }, 250)
    );

    this.noise2D = simplex.createNoise2D();
  }

  setBounds({
    canvasHeight,
    posX = 0.85, // 0..1
    posY = 0.7, // 0..1
  }: {
    canvasHeight?: number;
    posX?: number;
    posY?: number;
  }): Bounds {
    const viewWidth = window.innerWidth;
    const viewHeight = canvasHeight || window.innerHeight;

    // how far from the { x, y } origin can each orb move
    const maxDist = viewWidth < 1000 ? viewWidth / 3 : viewWidth / 5;
    // the { x, y } origin for each orb (the bottom right of the screen)
    const originX = viewWidth * posX;
    const originY = viewHeight * posY;

    // allow each orb to move x distance away from it's { x, y }origin
    return {
      x: {
        min: originX - maxDist,
        max: originX + maxDist,
      },
      y: {
        min: originY - maxDist,
        max: originY + maxDist,
      },
    };
  }

  update() {
    // self similar "pseudo-random" or noise values at a given point in "time"
    const xNoise = this.noise2D(this.xOff, this.xOff);
    const yNoise = this.noise2D(this.yOff, this.yOff);
    const scaleNoise = this.noise2D(this.xOff, this.yOff);

    // map the xNoise/yNoise values (between -1 and 1) to a point within the orb's bounds
    this.x = map(xNoise, -1, 1, this.bounds["x"].min, this.bounds["x"].max);
    this.y = map(yNoise, -1, 1, this.bounds.y.min, this.bounds.y.max);
    // map scaleNoise (between -1 and 1) to a scale value somewhere between half of the orb's original size, and 100% of it's original size
    this.scale = map(scaleNoise, -1, 1, 0.5, 1);

    // step through "time"
    this.xOff += this.inc;
    this.yOff += this.inc;
  }

  render() {
    // update the PIXI.Graphics position and scale values
    this.graphics.x = this.x;
    this.graphics.y = this.y;
    this.graphics.scale.set(this.scale);

    // clear anything currently drawn to graphics
    this.graphics.clear();

    // tell graphics to fill any shapes drawn after this with the orb's fill color
    this.graphics.fill(this.fill);
    // draw a circle at { 0, 0 } with it's size set by this.radius
    this.graphics.circle(0, 0, this.radius);
    // let graphics know we won't be filling in any more shapes
    this.graphics.fill();
  }
}
// map a number from 1 range to another
function map(
  n: number,
  start1: number,
  end1: number,
  start2: number,
  end2: number
) {
  return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
}

